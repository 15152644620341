import { useEffect, useState } from "react";
import "./App.css";
import { Installed } from "./components/Installed/Installed";
import { Main } from "./components/Main/Main";
import { useReactPWAInstall } from "react-pwa-install";

function App() {
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
  const [animating, setAnimating] = useState(0);
  const [megabyte, setMegabyte] = useState(0);

  const param = new URLSearchParams(document.location.search);

  let external_id = param.get("external_id");
  let creative_id = param.get("creative_id");
  let creo_id = param.get("creo_id");
  let ad_campaign_id = param.get("ad_campaign_id");
  let source = param.get("source");
  let site_id = param.get("site_id");
  let cost = param.get("cost");
  let offer = param.get("offer");
  let sub_id_1 = param.get("sub_id_1");
  let sub_id_2 = param.get("sub_id_2");
  let sub_id_3 = param.get("sub_id_3");
  let id = param.get("id");
  let feed_id = param.get("feed_id");

  if (feed_id) {
    localStorage.setItem("feed_id", feed_id);
  } else {
    feed_id = localStorage.getItem("feed_id");
  }
  if (sub_id_1) {
    localStorage.setItem("sub_id_1", sub_id_1);
  } else {
    sub_id_1 = localStorage.getItem("sub_id_1");
  }
  if (sub_id_2) {
    localStorage.setItem("sub_id_2", sub_id_2);
  } else {
    sub_id_2 = localStorage.getItem("sub_id_2");
  }
  if (sub_id_3) {
    localStorage.setItem("sub_id_3", sub_id_3);
  } else {
    sub_id_3 = localStorage.getItem("sub_id_3");
  }

  if (cost) {
    localStorage.setItem("cost", cost);
  } else {
    cost = localStorage.getItem("cost");
  }
  if (creo_id) {
    localStorage.setItem("creo_id", creo_id);
  } else {
    creo_id = localStorage.getItem("creo_id");
  }
  if (external_id) {
    localStorage.setItem("external_id", external_id);
  } else {
    external_id = localStorage.getItem("external_id");
  }
  if (site_id) {
    localStorage.setItem("site_id", site_id);
  } else {
    site_id = localStorage.getItem("site_id");
  }
  if (creative_id) {
    localStorage.setItem("creative_id", creative_id);
  } else {
    creative_id = localStorage.getItem("creative_id");
  }
  if (ad_campaign_id) {
    localStorage.setItem("ad_campaign_id", ad_campaign_id);
  } else {
    ad_campaign_id = localStorage.getItem("ad_campaign_id");
  }
  if (source) {
    localStorage.setItem("source", source);
  } else {
    source = localStorage.getItem("source");
  }

  if (id) {
    localStorage.setItem("id", id);
  } else {
    id = localStorage.getItem("id");
  }

  if (offer) {
    localStorage.setItem("offer", offer);
  } else {
    offer = localStorage.getItem("offer");
  }

  useEffect(() => {
    if (localStorage.getItem("isAlreadyDownload")) {
      if (id === "1011") {
        window.location.href = `https://arumo.top/vsDWnyDy?creative_id=${creative_id}&source=${source}`;
      } else if (id === "1007") {
        window.location.href = `https://arumo.top/tf6S7FF5?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1009") {
        window.location.href = `https://arumo.top/sL2Sd9S5?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (id === "1008") {
        window.location.href = `https://arumo.top/wPJBrJ69?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (id === "1205") {
        window.location.href = `https://arumo.top/hKdvYDmg?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (id === "1293") {
        window.location.href = `https://arumo.top/NcvRv43S?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1294") {
        window.location.href = `https://arumo.top/n7pkbd6L?cost=${cost}&external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (id === "1295") {
        window.location.href = `https://arumo.top/TD6vhvj5?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}&sub_id_1=${sub_id_1}`;
      } else if (id === "1296") {
        window.location.href = `https://arumo.top/3V49F4xD?creative_id=${creative_id}&source=${source}`;
      } else if (id === "1297") {
        window.location.href = `https://arumo.top/4tYkbq3j?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (id === "1456") {
        window.location.href = `https://arumo.top/npzdbgnZ?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1457") {
        window.location.href = `https://arumo.top/hZwMCQWN?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}&feed_id=${feed_id}`;
      } else if (id === "1462") {
        window.location.href = `https://arumo.top/k9c6KQyg?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (id === "1463") {
        window.location.href = `https://arumo.top/FRPsg5yJ?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (id === "1464") {
        window.location.href = `https://arumo.top/wzj92L7Y?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1465") {
        window.location.href = `https://arumo.top/gJmWsx8M?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1561") {
        window.location.href = `https://arumo.top/DWK9MnNL?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1562") {
        window.location.href = `https://arumo.top/ZH689fgh?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1552") {
        window.location.href = `https://arumo.top/sjbmvyqY?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1584") {
        window.location.href = `https://arumo.top/cn2y1LmB/?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else {
        window.location.href = `https://arumo.top/vsDWnyDy?creative_id=${creative_id}&source=${source}`;
      }
    }
  }, []);

  const animationMegabyte = () => {
    setTimeout(() => {
      setMegabyte(0.82);
    }, 1500);

    setTimeout(() => {
      setMegabyte(0.92);
    }, 2700);

    setTimeout(() => {
      setMegabyte(1.27);
    }, 4200);

    setTimeout(() => {
      setMegabyte(1.63);
    }, 5100);

    setTimeout(() => {
      setMegabyte(1.84);
    }, 6000);

    setTimeout(() => {
      setMegabyte(2.41);
    }, 7800);

    setTimeout(() => {
      setMegabyte(2.63);
    }, 9800);

    setTimeout(() => {
      setMegabyte(2.91);
    }, 11700);

    setTimeout(() => {
      setMegabyte(3.2);
    }, 13600);

    setTimeout(() => {
      setMegabyte("Installing...");
    }, 15400);
  };

  const handleClick = () => {
    const userAgent = window.navigator.userAgent;

    if (/iPhone/i.test(userAgent)) {
      if (id === "1011") {
        window.location.href = `https://arumo.top/vsDWnyDy?creative_id=${creative_id}&source=${source}`;
      } else if (id === "1007") {
        window.location.href = `https://arumo.top/tf6S7FF5?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1009") {
        window.location.href = `https://arumo.top/sL2Sd9S5?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (id === "1008") {
        window.location.href = `https://arumo.top/wPJBrJ69?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (id === "1205") {
        window.location.href = `https://arumo.top/hKdvYDmg?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (id === "1293") {
        window.location.href = `https://arumo.top/NcvRv43S?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1294") {
        window.location.href = `https://arumo.top/n7pkbd6L?cost=${cost}&external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (id === "1295") {
        window.location.href = `https://arumo.top/TD6vhvj5?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}&sub_id_1=${sub_id_1}`;
      } else if (id === "1296") {
        window.location.href = `https://arumo.top/3V49F4xD?creative_id=${creative_id}&source=${source}`;
      } else if (id === "1297") {
        window.location.href = `https://arumo.top/4tYkbq3j?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (id === "1456") {
        window.location.href = `https://arumo.top/npzdbgnZ?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1457") {
        window.location.href = `https://arumo.top/hZwMCQWN?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}&feed_id=${feed_id}`;
      } else if (id === "1462") {
        window.location.href = `https://arumo.top/k9c6KQyg?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (id === "1463") {
        window.location.href = `https://arumo.top/FRPsg5yJ?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (id === "1464") {
        window.location.href = `https://arumo.top/wzj92L7Y?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1465") {
        window.location.href = `https://arumo.top/gJmWsx8M?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1561") {
        window.location.href = `https://arumo.top/DWK9MnNL?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1562") {
        window.location.href = `https://arumo.top/ZH689fgh?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1552") {
        window.location.href = `https://arumo.top/sjbmvyqY?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1584") {
        window.location.href = `https://arumo.top/cn2y1LmB/?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else {
        window.location.href = `https://arumo.top/vsDWnyDy?creative_id=${creative_id}&source=${source}`;
      }
    } else {
      pwaInstall({
        title: "Install Web App",
      })
        .then(() => {
          setAnimating(true);
          animationMegabyte();
          setTimeout(() => {
            localStorage.setItem("isAlreadyDownload", true);
            setAnimating("done");
          }, 17400);

          async function handleNotifications() {
            try {
              const permissionResult = await Notification.requestPermission();
              if (permissionResult === "granted") {
              } else {
                console.log("Notification permission denied");
              }
            } catch (error) {
              console.error("Error requesting notification permission:", error);
            }
            setAnimating("done");
          }

          setTimeout(() => {
            handleNotifications();
          }, 17700);
        })
        .catch(() => {
          setTimeout(() => {
            if (id === "1011") {
              window.location.href = `https://arumo.top/vsDWnyDy?creative_id=${creative_id}&source=${source}`;
            } else if (id === "1007") {
              window.location.href = `https://arumo.top/tf6S7FF5?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
            } else if (id === "1009") {
              window.location.href = `https://arumo.top/sL2Sd9S5?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
            } else if (id === "1008") {
              window.location.href = `https://arumo.top/wPJBrJ69?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
            } else if (id === "1205") {
              window.location.href = `https://arumo.top/hKdvYDmg?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
            } else if (id === "1293") {
              window.location.href = `https://arumo.top/NcvRv43S?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
            } else if (id === "1294") {
              window.location.href = `https://arumo.top/n7pkbd6L?cost=${cost}&external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
            } else if (id === "1295") {
              window.location.href = `https://arumo.top/TD6vhvj5?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}&sub_id_1=${sub_id_1}`;
            } else if (id === "1296") {
              window.location.href = `https://arumo.top/3V49F4xD?creative_id=${creative_id}&source=${source}`;
            } else if (id === "1297") {
              window.location.href = `https://arumo.top/4tYkbq3j?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
            } else if (id === "1456") {
              window.location.href = `https://arumo.top/npzdbgnZ?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
            } else if (id === "1457") {
              window.location.href = `https://arumo.top/hZwMCQWN?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}&feed_id=${feed_id}`;
            } else if (id === "1462") {
              window.location.href = `https://arumo.top/k9c6KQyg?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
            } else if (id === "1463") {
              window.location.href = `https://arumo.top/FRPsg5yJ?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
            } else if (id === "1464") {
              window.location.href = `https://arumo.top/wzj92L7Y?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
            } else if (id === "1465") {
              window.location.href = `https://arumo.top/gJmWsx8M?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
            } else if (id === "1561") {
              window.location.href = `https://arumo.top/DWK9MnNL?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
            } else if (id === "1562") {
              window.location.href = `https://arumo.top/ZH689fgh?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
            } else if (id === "1552") {
              window.location.href = `https://arumo.top/sjbmvyqY?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
            } else if (id === "1584") {
              window.location.href = `https://arumo.top/cn2y1LmB/?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
            } else {
              window.location.href = `https://arumo.top/vsDWnyDy?creative_id=${creative_id}&source=${source}`;
            }
          }, 400);
        });
    }
    setTimeout(() => {
      document.querySelector(".MuiButton-containedPrimary").click();
    }, 5);
  };

  return (
    <div className="App">
      {supported() && !isInstalled() ? (
        <Main
          handleClick={handleClick}
          animating={animating}
          setAnimating={setAnimating}
          megabyte={megabyte}
          supported={true}
        />
      ) : isInstalled() ? (
        <Installed />
      ) : (
        <>
          <Main
            handleClick={handleClick}
            animating={animating}
            setAnimating={setAnimating}
            megabyte={megabyte}
            supported={false}
          />
        </>
      )}
    </div>
  );
}

export default App;
